import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <footer className="footer">
        <div className="footer-container">
            <hr className='footrt-hr'/>
            <div className="footer-content">
                <div className="footer-col1">
                    <h4>Smart4Technologies</h4>
                    <p>Smart4Technologies (S4T) delivers cutting-edge software solutions, revolutionizing hydrogen system planning and modeling. Our technology empowers global experts to optimize energy strategies with precision.</p>
                </div>
                <div className="footer-col2">
                    <h4>Useful Links</h4>
                    <ul>
                        <li>
                            <Link to='/#Home' className='link' aria-label="Navigate to Home page">Home</Link>
                        </li>
                        <li>
                            <Link to='/#Services' className='link' aria-label="Navigate to Services page">Services</Link>
                        </li>
                        <li>
                            <Link to='/#Calculator' className='link' aria-label="Navigate to Calculator page">Calculator</Link>
                        </li>
                        <li>
                            <Link to='/#Contact' className='link' aria-label="Navigate to Contact page">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-col3">
                    <h4>Legal</h4>
                    <ul>
                        <li>
                            <Link to='/TermsOfUse' className='link' aria-label="Read Terms of Use">Terms of Use</Link>
                        </li>
                        <li>
                            <Link to='/Privacy&Policy' className='link' aria-label="Read Privacy Policy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to='/#About' className='link' aria-label="Navigate to About Us page">About Us</Link>
                        </li>
                        <li>
                            <Link to='/OurTeam' className='link' aria-label="Learn about Our Team">Our Team</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-col4">
                    <h4>Subscribe</h4>
                    <div className="social-links">
                        <Link onClick={() => { window.open('', '_blank') }} aria-label="Open social media link 1">
                            <svg width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#30ca59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                            </svg> 
                        </Link>
                        <Link onClick={() => { window.open('', '_blank') }} aria-label="Open social media link 2">
                            <svg height="19" width="19" viewBox="0 0 512 512" fill="#30ca59">
                                <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                            </svg>
                        </Link>
                        <Link onClick={() => { window.open('https://www.linkedin.com/company/smart4technologies/about/', '_blank') }} aria-label="Visit Smart4Technologies LinkedIn page">
                            <svg width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#30ca59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                <rect x="2" y="9" width="4" height="12"></rect>
                                <circle cx="4" cy="4" r="2"></circle>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <p className="copyright">© 2024 All Rights Reserved By Smart4Technologies ©</p>
    </footer>
  )
}

export default Footer
