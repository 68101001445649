// items.js
import Img1 from '../Images/TeamMember1.png'
import Img2 from '../Images/TeamMember2.png'
import Img3 from '../Images/TeamMember3.png'
import Img4 from '../Images/TeamMember4.jpg'
import Img5 from '../Images/TeamMember5.jpg'
import Img6 from '../Images/seemore.png'

const items = [
    {
      id: 1,
      name: "Mourad Nachtane",
      designation: "S4T CEO",
      image: Img1,
    },
    {
      id: 2,
      name: "Khaoula Adeli",
      designation: "S4T Researcher",
      image: Img2,
    },
    {
      id: 3,
      name: "Abdelilah Zaari",
      designation: "Software Developer and Project Manager",
      image: Img3,
    },
    {
      id: 4,
      name: "Abd. Ait El Mouddene",
      designation: "Big Data and IoT Specialist",
      image: Img4,
    },
    {
      id: 5,
      name: "Achraf El Makaoui",
      designation: "Frontend Developer",
      image: Img5,
    },
    {
      id: 6,
      name: "See More",
      link: "/OurTeam",
      image: Img6,
    },
  ];
  
  export default items;
  