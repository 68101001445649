import React from 'react';
import './Intro.css';
import { useTheme } from '../../ThemeContext';



const Intro = () => {
  const { theme } = useTheme();

  return (
    <section className='intro-section' id='intro'>
      <div className='IntroContainer'>
        <div className='intro-bar'>
            {theme==='light' &&
            <>
              <svg id="logo" width="288" height="264" viewBox="0 0 96 88" fill="none">
                <path d="M5.88477 60.8867C5.65321 60.876 5.42094 60.88 5.18945 60.9004C4.57219 60.9547 3.96218 61.1216 3.40039 61.4121C-0.150409 63.2483 0.329779 68.7092 4.16211 70.0761C8.31871 71.5587 12.1098 66.9771 9.88281 63.164C9.07757 61.7853 7.50568 60.9619 5.88477 60.8867Z" stroke="#30ca59"/>
                <path d="M70.4867 1.74982L69.6113 2.49219C69.0981 3.07673 67.0673 4.40881 65.0976 5.45312C59.5431 8.39811 56.9641 11.0896 56.2109 14.7266C55.8952 16.2511 55.9169 17.0066 56.3574 19.6719L56.543 20.793L57.4277 18.873C58.4963 16.5526 60.1601 14.8468 62.6504 13.5195C63.6735 12.9742 65.1513 11.9637 65.9355 11.2734C67.0601 10.2836 67.3613 10.14 67.3613 10.5957C67.3613 10.9145 65.648 12.8274 63.539 14.8633C59.6478 18.6198 58.4305 20.6959 58.5187 22.5039C58.5588 23.3248 58.3255 23.1044 58.8738 23.1474C59.3679 23.1862 61.6139 22.9319 61.7947 22.9034C62.6442 22.7697 64.3507 22.5661 65.5153 22.2444C68.4655 21.4295 71.209 19.5774 72.3375 15.8977C72.7784 14.4597 73.0354 12.8581 72.8187 9.98677C72.4282 7.42779 73.1647 11.349 72.1295 6.27376C71.8516 4.91121 71.2059 3.59379 71.1073 3.20047L70.4867 1.74982Z" stroke="#30ca59"/>
                <path d="M25.5098 12.3633C25.1376 12.3585 24.7687 12.3626 24.4024 12.375C13.4119 12.7472 5.11614 20.7952 5.68361 31.0332C5.93711 35.6067 7.68119 39.1803 11.3731 42.6875L13.2012 44.4238L11.6465 45.9785C8.86625 48.7587 6.48986 52.7731 5.60743 56.1836C5.40706 56.958 5.17144 57.8443 5.08204 58.1523C4.96026 58.572 5.10514 58.7129 5.66407 58.7129C6.30105 58.7129 6.46643 58.468 6.79883 57.0332C7.48452 54.0733 9.67557 50.2143 12.168 47.5723L14.4512 45.1504L15.3867 45.8164C15.9015 46.183 19.2973 48.1472 22.9316 50.1816C30.9947 54.6951 33.3023 56.0924 36.041 58.1113C38.3911 59.8439 42.552 64.07 43.1074 65.2891C43.4585 66.0596 43.5326 66.0723 47.4043 66.0723H51.3457L51.1641 65.0332C50.7602 62.7153 49.6587 59.951 48.4336 58.1836C45.0765 53.3409 41.6841 50.8767 29.0938 44.1367C25.237 42.0721 20.9047 39.6017 19.4668 38.6465C12.9121 34.292 10.1004 29.5936 11.0293 24.5527C12.3163 17.569 19.1286 12.8511 27.1387 13.3965C35.0545 13.9355 41.5255 18.4702 45.0117 25.9199C45.5454 27.0603 46.0399 27.9922 46.1113 27.9922C46.1827 27.9922 46.2422 25.0441 46.2422 21.4414V14.8926L45.0762 15.6816C43.2471 16.9229 41.7748 16.75 35.2871 14.5273C30.4472 12.8692 29.1702 12.5556 26.6348 12.4043C26.2574 12.3818 25.8819 12.3681 25.5098 12.3633ZM6.51758 72.4727L7.58008 74.4082C11.1984 81.0068 17.8633 85.5172 25.5039 86.5371C32.1284 87.4214 40.4547 84.9302 45.1797 80.6504C46.9675 79.0312 49.7617 75.3875 49.7617 74.6758C49.7617 74.5198 48.6301 74.3926 47.2461 74.3926H44.7285L44.1563 75.9121C42.5121 80.2742 38.8575 83.2876 33.5742 84.6387C24.2317 87.0281 13.213 82.1734 8.64844 73.6543C8.19589 72.8097 7.80318 72.4727 7.26758 72.4727H6.51758Z" stroke="#2d2e32"/>
                <path d="M38.8926 62.1699C38.5274 62.2148 37.873 62.8413 36.3183 64.5234L33.8379 67.209L33.8359 70.3281L33.8339 73.4492H45.994H58.1542V70.2481V67.0488H46.9276H35.703L37.6346 64.9063C39.2148 63.1555 39.4974 62.681 39.1835 62.3027C39.1033 62.2061 39.0143 62.155 38.8926 62.1699ZM70.3125 67.0488V70.2481V73.4492H75.7539H81.1933V70.2481V67.0488H75.7539H70.3125Z" stroke="#2d2e32"/>
                <path d="M94.7122 31.209L81.1272 31.2227L70.6713 31.2325C70.6763 31.7238 70.6704 31.395 70.6733 31.6289L70.6793 32.0078L70.6773 32.2774L70.6793 32.4844L71.9104 32.4649C82.9014 32.278 87.4678 32.6784 90.0301 34.0586C91.1771 34.6765 93.251 36.7572 94.1993 38.2403C94.6829 38.9967 94.7052 38.8582 94.7052 35.1192L94.7122 31.209Z" stroke="#2d2e32"/>
                <path d="M66.3809 31.2227C65.1486 32.5798 63.9598 33.9773 62.6953 35.3047C61.1433 37.089 60.571 37.8564 59.21 39.3344C59.213 39.3583 59.204 40.046 59.206 40.0703C59.2 40.9345 59.214 40.3416 59.202 41.3262C59.1528 47.621 59.1335 53.9189 59.1041 60.2148C59.0155 79.1308 59.0074 79.3718 58.3248 80.6367C56.7161 83.6187 54.1455 84.9743 49.6587 85.209C46.7997 85.3585 46.6939 85.3915 46.6939 86.1211V86.8769H63.6548H80.6138V86.1172C80.6138 85.3899 80.5019 85.3516 77.9712 85.2187C75.82 85.1058 75.0069 84.9058 73.6119 84.1465C72.6689 83.6332 71.5585 82.7672 71.1431 82.2226C69.3871 79.9199 69.4315 80.3624 69.4146 55.4766L69.399 32.5039C69.405 32.4584 69.402 32.2656 69.399 32.2207V32.0195L69.403 31.6738C69.393 31.5445 69.396 31.509 69.403 31.2246L66.3809 31.2227Z" stroke="#2d2e32"/>
                <path d="M58.0646 40.2083C57.8164 40.4875 57.7122 40.5799 57.3934 40.9357L52.7865 46.23C49.3278 50.1923 46.5704 53.5543 46.6579 53.7008C47.0716 54.394 47.9818 53.5457 52.8999 47.8931L58.231 41.7673C58.234 41.532 58.2291 41.7712 58.231 41.3884C58.238 41.321 58.225 41.016 58.233 40.9584C58.237 40.9341 58.228 40.566 58.231 40.5416C58.234 40.5098 58.2269 40.3442 58.2309 40.3124C58.2349 40.2825 58.2279 40.0674 58.2329 40.0378L58.0646 40.2083Z" stroke="#2d2e32"/>
                <path d="M34.6 31.248V35.3027V39.3574L35.5844 37.873C37.7595 34.5891 41.4215 32.8667 46.7601 32.6191C49.6713 32.484 50.3664 32.5557 51.9613 33.1581C53.9883 33.9238 56.5481 35.7931 57.6078 37.2812L58.1996 38.0429C58.2456 38.0112 58.2863 37.925 58.3304 37.8906C58.4004 37.8389 58.4091 37.792 58.4789 37.7402C58.5662 37.6474 58.5933 37.6095 58.6722 37.5273C58.7491 37.459 58.7624 37.4251 58.8519 37.3222C58.9345 37.2511 58.9405 37.2161 59.0062 37.1484C59.0198 37.1341 59.1744 36.9535 59.1879 36.9394C59.0917 36.79 58.788 36.5591 58.4633 36.1952C57.3578 34.9569 54.1882 32.5735 51.9769 31.8906C50.1365 31.3221 49.0023 31.248 42.2465 31.248L34.6 31.248Z" stroke="#2d2e32"/>
              </svg>
            </>
          }
          {theme==='dark' &&
            <>
              <svg id="logo" width="288" height="264" viewBox="0 0 96 88" fill="none">
                <path d="M5.88477 60.8867C5.65321 60.876 5.42094 60.88 5.18945 60.9004C4.57219 60.9547 3.96218 61.1216 3.40039 61.4121C-0.150409 63.2483 0.329779 68.7092 4.16211 70.0761C8.31871 71.5587 12.1098 66.9771 9.88281 63.164C9.07757 61.7853 7.50568 60.9619 5.88477 60.8867Z" stroke="#30ca59" fill='#30ca59'/>
                <path d="M70.4867 1.74982L69.6113 2.49219C69.0981 3.07673 67.0673 4.40881 65.0976 5.45312C59.5431 8.39811 56.9641 11.0896 56.2109 14.7266C55.8952 16.2511 55.9169 17.0066 56.3574 19.6719L56.543 20.793L57.4277 18.873C58.4963 16.5526 60.1601 14.8468 62.6504 13.5195C63.6735 12.9742 65.1513 11.9637 65.9355 11.2734C67.0601 10.2836 67.3613 10.14 67.3613 10.5957C67.3613 10.9145 65.648 12.8274 63.539 14.8633C59.6478 18.6198 58.4305 20.6959 58.5187 22.5039C58.5588 23.3248 58.3255 23.1044 58.8738 23.1474C59.3679 23.1862 61.6139 22.9319 61.7947 22.9034C62.6442 22.7697 64.3507 22.5661 65.5153 22.2444C68.4655 21.4295 71.209 19.5774 72.3375 15.8977C72.7784 14.4597 73.0354 12.8581 72.8187 9.98677C72.4282 7.42779 73.1647 11.349 72.1295 6.27376C71.8516 4.91121 71.2059 3.59379 71.1073 3.20047L70.4867 1.74982Z" stroke="#30ca59"/>
                <path d="M25.5098 12.3633C25.1376 12.3585 24.7687 12.3626 24.4024 12.375C13.4119 12.7472 5.11614 20.7952 5.68361 31.0332C5.93711 35.6067 7.68119 39.1803 11.3731 42.6875L13.2012 44.4238L11.6465 45.9785C8.86625 48.7587 6.48986 52.7731 5.60743 56.1836C5.40706 56.958 5.17144 57.8443 5.08204 58.1523C4.96026 58.572 5.10514 58.7129 5.66407 58.7129C6.30105 58.7129 6.46643 58.468 6.79883 57.0332C7.48452 54.0733 9.67557 50.2143 12.168 47.5723L14.4512 45.1504L15.3867 45.8164C15.9015 46.183 19.2973 48.1472 22.9316 50.1816C30.9947 54.6951 33.3023 56.0924 36.041 58.1113C38.3911 59.8439 42.552 64.07 43.1074 65.2891C43.4585 66.0596 43.5326 66.0723 47.4043 66.0723H51.3457L51.1641 65.0332C50.7602 62.7153 49.6587 59.951 48.4336 58.1836C45.0765 53.3409 41.6841 50.8767 29.0938 44.1367C25.237 42.0721 20.9047 39.6017 19.4668 38.6465C12.9121 34.292 10.1004 29.5936 11.0293 24.5527C12.3163 17.569 19.1286 12.8511 27.1387 13.3965C35.0545 13.9355 41.5255 18.4702 45.0117 25.9199C45.5454 27.0603 46.0399 27.9922 46.1113 27.9922C46.1827 27.9922 46.2422 25.0441 46.2422 21.4414V14.8926L45.0762 15.6816C43.2471 16.9229 41.7748 16.75 35.2871 14.5273C30.4472 12.8692 29.1702 12.5556 26.6348 12.4043C26.2574 12.3818 25.8819 12.3681 25.5098 12.3633ZM6.51758 72.4727L7.58008 74.4082C11.1984 81.0068 17.8633 85.5172 25.5039 86.5371C32.1284 87.4214 40.4547 84.9302 45.1797 80.6504C46.9675 79.0312 49.7617 75.3875 49.7617 74.6758C49.7617 74.5198 48.6301 74.3926 47.2461 74.3926H44.7285L44.1563 75.9121C42.5121 80.2742 38.8575 83.2876 33.5742 84.6387C24.2317 87.0281 13.213 82.1734 8.64844 73.6543C8.19589 72.8097 7.80318 72.4727 7.26758 72.4727H6.51758Z" stroke="#f5f5f5"/>
                <path d="M38.8926 62.1699C38.5274 62.2148 37.873 62.8413 36.3183 64.5234L33.8379 67.209L33.8359 70.3281L33.8339 73.4492H45.994H58.1542V70.2481V67.0488H46.9276H35.703L37.6346 64.9063C39.2148 63.1555 39.4974 62.681 39.1835 62.3027C39.1033 62.2061 39.0143 62.155 38.8926 62.1699ZM70.3125 67.0488V70.2481V73.4492H75.7539H81.1933V70.2481V67.0488H75.7539H70.3125Z" stroke="#f5f5f5"/>
                <path d="M94.7122 31.209L81.1272 31.2227L70.6713 31.2325C70.6763 31.7238 70.6704 31.395 70.6733 31.6289L70.6793 32.0078L70.6773 32.2774L70.6793 32.4844L71.9104 32.4649C82.9014 32.278 87.4678 32.6784 90.0301 34.0586C91.1771 34.6765 93.251 36.7572 94.1993 38.2403C94.6829 38.9967 94.7052 38.8582 94.7052 35.1192L94.7122 31.209Z" stroke="#f5f5f5"/>
                <path d="M66.3809 31.2227C65.1486 32.5798 63.9598 33.9773 62.6953 35.3047C61.1433 37.089 60.571 37.8564 59.21 39.3344C59.213 39.3583 59.204 40.046 59.206 40.0703C59.2 40.9345 59.214 40.3416 59.202 41.3262C59.1528 47.621 59.1335 53.9189 59.1041 60.2148C59.0155 79.1308 59.0074 79.3718 58.3248 80.6367C56.7161 83.6187 54.1455 84.9743 49.6587 85.209C46.7997 85.3585 46.6939 85.3915 46.6939 86.1211V86.8769H63.6548H80.6138V86.1172C80.6138 85.3899 80.5019 85.3516 77.9712 85.2187C75.82 85.1058 75.0069 84.9058 73.6119 84.1465C72.6689 83.6332 71.5585 82.7672 71.1431 82.2226C69.3871 79.9199 69.4315 80.3624 69.4146 55.4766L69.399 32.5039C69.405 32.4584 69.402 32.2656 69.399 32.2207V32.0195L69.403 31.6738C69.393 31.5445 69.396 31.509 69.403 31.2246L66.3809 31.2227Z" stroke="#f5f5f5"/>
                <path d="M58.0646 40.2083C57.8164 40.4875 57.7122 40.5799 57.3934 40.9357L52.7865 46.23C49.3278 50.1923 46.5704 53.5543 46.6579 53.7008C47.0716 54.394 47.9818 53.5457 52.8999 47.8931L58.231 41.7673C58.234 41.532 58.2291 41.7712 58.231 41.3884C58.238 41.321 58.225 41.016 58.233 40.9584C58.237 40.9341 58.228 40.566 58.231 40.5416C58.234 40.5098 58.2269 40.3442 58.2309 40.3124C58.2349 40.2825 58.2279 40.0674 58.2329 40.0378L58.0646 40.2083Z" stroke="#f5f5f5"/>
                <path d="M34.6 31.248V35.3027V39.3574L35.5844 37.873C37.7595 34.5891 41.4215 32.8667 46.7601 32.6191C49.6713 32.484 50.3664 32.5557 51.9613 33.1581C53.9883 33.9238 56.5481 35.7931 57.6078 37.2812L58.1996 38.0429C58.2456 38.0112 58.2863 37.925 58.3304 37.8906C58.4004 37.8389 58.4091 37.792 58.4789 37.7402C58.5662 37.6474 58.5933 37.6095 58.6722 37.5273C58.7491 37.459 58.7624 37.4251 58.8519 37.3222C58.9345 37.2511 58.9405 37.2161 59.0062 37.1484C59.0198 37.1341 59.1744 36.9535 59.1879 36.9394C59.0917 36.79 58.788 36.5591 58.4633 36.1952C57.3578 34.9569 54.1882 32.5735 51.9769 31.8906C50.1365 31.3221 49.0023 31.248 42.2465 31.248L34.6 31.248Z" stroke="#f5f5f5"/>
              </svg>
            </>
          }
        </div>
        <div className='intro-content'>
          <h1><span>Smart4Technologies</span></h1>
        </div>
      </div>
    </section>
  );
}

export default Intro;
